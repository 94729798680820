import React from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import { EuiImage, EuiSpacer, EuiText } from "@elastic/eui"
import AssessmentImage from "../../images/assessment.png"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"

const BookingNotApplicablePage = () => {
  const { customerStore } = useStore()

  const data = {
    title: "Completed",
  }

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={100}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <p>
          An Insurance Assessor will come to talk to you about your vehicle
          shortly.
        </p>
      </EuiText>
      <EuiSpacer />
      <EuiImage src={AssessmentImage} alt={data.title} size="1" />
      <HailCXRepairTypeAlert />
    </HailCXLayout>
  )
}

export default observer(BookingNotApplicablePage)
