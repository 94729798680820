import moment from 'moment'

export const getBookingDateUIFormatted = (date: string) => {
    const formattedDate = moment(date).format('dddd, MMMM DD');

    return formattedDate;
}

export const getBookingTimeUIFormatted = (time: string) => {
    const formattedTime = moment(time, 'hh:mm:ss').format('h:mm a');

    return formattedTime;
}

export const getBookingDateTimeUIFormatted = (date: string, time: string) => {
    const formattedDate = getBookingDateUIFormatted(date);
    const formattedTime = getBookingTimeUIFormatted(time);

    return `${formattedDate} @ ${formattedTime}`;
}

export const getCompletionDateUIFormatted = (date: string, durationInWeeks: number) => {
    const formattedCompletionDate = moment(date).add(durationInWeeks, 'weeks').format('dddd, MMMM DD');

    return formattedCompletionDate;
}

export const saveEntryPointUrl = () => {
    
    const entryPointUrl = `${location.pathname}${location.search}`;

    //set entry point url in local storage
    if(localStorage.getItem('hailcx-entrypoint-url') && (localStorage.getItem('hailcx-entrypoint-url') !== entryPointUrl))
        localStorage.removeItem('hailcx-entrypoint-url')
   
    localStorage.setItem('hailcx-entrypoint-url', entryPointUrl);
    
}

export const getEntryPointUrl = (): string | null => {
    //get entry point url from local storage
    return localStorage.getItem('hailcx-entrypoint-url');        
}

export const removeSavedEntryPointUrl = () => {
    //if(localStorage.getItem('hailcx-entrypoint-url'))
        localStorage.removeItem('hailcx-entrypoint-url')
}