import React, { useEffect } from "react";
import queryString from "query-string"
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody
} from "@elastic/eui";
import { ReactNodeLike } from "prop-types";
import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
import '../../styles/hailcx-layout.css';
import { useStore } from '../../stores';
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import HelpModal from "./help-modal";
import { getEntryPointUrl } from "../../entities/utilities";

interface Props {
  header: ReactNodeLike,
  footer?: ReactNodeLike,
  children: ReactNodeLike
}

const HailCXLayout = ({ header, footer, children }: Props) => {

  const { customerStore } = useStore();
  const { eqn } = queryString.parse(useLocation().search);

  useEffect(() => {

    //detect loss of customer context and if customer quote number doesn't exist in the url
    if (!customerStore.customer && !eqn) {
      //get entry point
      let entryPointUrl = getEntryPointUrl();
      if(entryPointUrl)
      {
        navigate(entryPointUrl);        
      }
      else
      {
        //if no entry point found, then send customer to error page
        navigate('/cannotproceed');
      }
      return;
    }
  }, [])

  return (
    <EuiPage paddingSize="none">
      <EuiPageBody>
        {header}
        <EuiPageContent style={{ background: '#F5F7FA' }} className="eui-textCenter" borderRadius="none" hasShadow={false} paddingSize="none">
          <EuiPageContentBody restrictWidth paddingSize="l">
            {children}
          </EuiPageContentBody>
        </EuiPageContent>
        {footer}
        {!footer &&
          <EuiFlexGroup component="span" gutterSize='l' justifyContent='center'>
            <EuiFlexItem component="span" >
              <HelpModal />
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      </EuiPageBody>
    </EuiPage>
  )
}

export default HailCXLayout;